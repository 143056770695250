import {
  useGetUserInfoQuery,
  CustomerUserInfoDataFragment,
  CustomerInfoFragment,
  UserType
} from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { AppCookie } from 'utils/cookies'

/**
 * Hook to extract active user account
 * information based on X-Customer-Id cookie
 */
const useActiveUserInfo = (): {
  loading: boolean
  user: CustomerUserInfoDataFragment
  customer: CustomerInfoFragment
  userType: UserType
} => {
  const { data, loading } = useGetUserInfoQuery()
  const customerId = Cookies.get(AppCookie.CustomerId)

  const user = data?.GetUserInfo?.users.find(
    user => user.customer?.id === customerId
  )

  return {
    user,
    customer: user?.customer,
    loading,
    userType: data?.GetUserInfo?.userType
  }
}

export default useActiveUserInfo
